<script lang="ts" setup>
import { useUID } from 'core'

defineProps<{
  label: string
  placeholder?: string
  labelClass?: string
  inputClass?: string
}>()

const id = useUID('settings-group-item')
const modelValue = defineModel()
</script>

<template>
  <div>
    <label :for="id" class="block max-w-max mb-3 text-sm text-neutral-500 font-semibold" :class="labelClass">{{ label }}</label>

    <slot>
      <input :id v-model="modelValue" :placeholder :class="inputClass">
    </slot>
  </div>
</template>
